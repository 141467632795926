import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import WebLayout from "layouts/web-layout";
import Forget from "./app-views/forget_password";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  const Location = useLocation();
  const instance = axios.create();
  axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if(error.response.data==="Unauthorized" && Location.pathname !=="/auth/login-2"){
        localStorage.removeItem("token");
        window.location.href="/auth/login-2"
        return;
      }
      if (error.response.data.message === "Failed to authenticate token!" && Location.pathname !== "/") {
        localStorage.removeItem("token");
        window.location.href = '/'
        return
      }
      if(error.response.data.message === "Your account has been deactivated, Please contact ST&T team!" && Location.pathname !=="/auth/login-2"){
        localStorage.removeItem("token");
        window.location.href = '/'
        return
      }
      throw error;
    }
  )

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <WebLayout />
          </Route>
          <Route path={"/auth/login-2/email/forgot-password"}>
          <Forget/>
          </Route>
          <Route path="/web">
            <WebLayout />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location}/>
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));